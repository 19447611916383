import { Helmet } from 'react-helmet';

type Props = {
  keywords: string;
  title: string;
};

export default function Head({ title, keywords }: Props) {
  return (
    <Helmet meta={[{ content: `${title}, ${keywords}`, name: 'keywords' }]} />
  );
}
