import CatalogImage from 'components/MediaServerImage/CatalogImage';
import Dropdown from 'components/Dropdown/PodcastDropdown';
import NavLink from 'components/NavLink';
import NewEpisodeBadge from 'components/NewEpisodeBadge/NewEpisodeBadge';
import Tile from 'components/Tile/Tile';
import Tiles from 'components/Tiles/Tiles';
import Truncate from 'components/Truncate';
import { Data as ItemSelectedData } from 'modules/Analytics/helpers/itemSelected';
import { PlaybackTypeValue } from 'constants/stationTypes';
import { Podcast } from 'state/Podcast/types';

type Props = {
  hasBottomMargin?: boolean;
  imgWidth: number;
  itemSelectedLocation: string;
  podcasts: Array<Podcast>;
  singleRow?: boolean;
  tilesInRow?: number;
};

function PodcastTiles({
  hasBottomMargin,
  imgWidth,
  itemSelectedLocation,
  podcasts,
  singleRow = false,
  tilesInRow = 3,
}: Props) {
  if (!podcasts.length) return null;
  const limit = singleRow ? tilesInRow : Infinity;

  return (
    <Tiles tilesInRow={tilesInRow}>
      {podcasts
        .slice(0, limit)
        .map(
          ({
            title,
            description,
            imgUrl,
            url,
            followed,
            seedType,
            seedId,
            newEpisodeCount,
          }) => {
            const subTitle = <Truncate lines={2}>{description}</Truncate>;

            const itemSelected = {
              id: seedId,
              location: itemSelectedLocation,
              name: title,
              type: seedType as PlaybackTypeValue,
            } as ItemSelectedData;

            return (
              <Tile
                dropdown={
                  <Dropdown
                    followed={followed}
                    key={`dropdown-${seedId}`}
                    seedId={seedId}
                    title={title}
                  />
                }
                hasBottomMargin={hasBottomMargin}
                itemSelected={itemSelected}
                key={seedId}
                singleRow={singleRow}
                subTitle={subTitle}
                tilesInRow={tilesInRow}
                title={title}
                titleSingleLine
                url={url}
              >
                <NavLink itemSelected={itemSelected} to={url}>
                  <NewEpisodeBadge newEpisodeCount={newEpisodeCount} />
                  <CatalogImage
                    alt={title}
                    aspectRatio={1}
                    height={imgWidth}
                    id={seedId}
                    src={imgUrl}
                    type={seedType}
                    width={imgWidth}
                  />
                </NavLink>
              </Tile>
            );
          },
        )}
    </Tiles>
  );
}

export default PodcastTiles;
